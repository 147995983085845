<style lang="scss">
.agreement-popup {
    padding: 0 15px 50px;
    .title {
        text-align: center;
        font-size: 20px;
        padding-bottom: 30px;
    }
    h1 {
        font-size: 18px;
    }
    h2 {
        font-size: 16px;
    }
    p {
        font-size: 14px;
        line-height: 1.6;
        text-indent: 28px;
    }
}
</style>
<template>
    <div class="mem-content">
        <div class="image">
            <el-image v-if="!showOpen" :src="imgScope" fit="fill"></el-image>
            <el-image v-else :src="imgPay" fit="fill"></el-image>
        </div>
        <div class="table" v-show="!showOpen">
            <el-table :data="tableData" center :span-method="objectSpanMethod" :header-cell-style="discountHeaderStyle" border style="width: 100%; margin-top: 20px">
                <el-table-column align="center">
                    <template slot="header">
                        <div>对比项</div>
                    </template>
                    <el-table-column prop="type" align="center" width="150">
                        <template slot-scope="props">
                            <div  class="bold">{{props.row.type}}</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="desc"></el-table-column>
                </el-table-column>
                <el-table-column width="182" align="center">
                    <template slot="header">
                        <div>游客</div>
                    </template>
                    <template  slot-scope="scope">
                        <el-image v-if="scope.row.tourists===false" class="open" :src="imgFalse" fit="fill"></el-image>
                        <el-image v-else :src="imgTrue" class="open" fit="fill"></el-image>
                    </template>
                </el-table-column>
                <el-table-column width="252" align="center">
                    <template slot="header">
                        <div class="trial">
                            <div>试用会员</div>
                            <div class="price">{{memberTemp.dayPrice}}/天</div>
                            <div>
                                <el-button round v-if="$store.state.user.roleId === $variable.member.temp">已开通</el-button>
                                <el-image v-else class="img1" :src="imgOpen"
                                          fit="fill" @click="openDay"></el-image>
                            </div>
                        </div>
                    </template>
                    <template  slot-scope="scope">
                        <div v-if="scope.row.open===false">
                            <el-image class="open" :src="imgFalse" fit="fill"></el-image>
                        </div>
                        <div v-else>
                            <el-image :src="imgTrue" class="open" fit="fill"></el-image>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column align="center"  width="265">
                    <template slot="header">
                        <div class="trial">
                            <div>VIP会员</div>
                            <div class="price">{{memberVip.yearPrice}}/年</div>
                            <el-image class="recom" :src="imgRecom" fit="fill"></el-image>
                            <div>
                                <el-button round v-if="$store.state.user.roleId === $variable.member.vip" @click="openYear">已开通</el-button>
                                <el-image v-else class="img1" :src="imgOpen"
                                          fit="fill" @click="openYear"></el-image>
                            </div>
                            <div class="recommended">荐</div>
                        </div>

                    </template>
                    <template  slot-scope="scope">
                        <div v-if="scope.row.vip===false">
                            <el-image :src="imgFalse" class="open" fit="fill"></el-image>
                        </div>
                        <div v-else>
                            <el-image :src="imgTrue" class="open" fit="fill"></el-image>
                        </div>
                    </template>
                </el-table-column>
            </el-table>
        </div>

        <div class="open-pay" v-show="showOpen">
            <div class="pay-content">
                <div class="left mem" :class="{'active': showDay}" @click="clickDay">
                    <div class="mem-title">试用会员</div>
                    <div class="money">{{memberTemp.dayPrice}}元<span>/天</span></div>
                    <div>
                        <p>24小时内享受 全站会员权限 无限制查阅</p>
                    </div>
                    <el-image class="scale" :src="imgScale" fit="fill"></el-image>
                </div>
                <div class="right mem" :class="{'active': showYear}" @click="clickYear">
                    <div class="mem-title">VIP会员</div>
                    <div class="money">{{memberVip.yearPrice}}<span>/年</span></div>
                    <div>
                        <p>年购优惠享受 全站会员权限 无限制查阅</p>
                    </div>
                    <el-image class="recom" :src="imgRecom" fit="fill"></el-image>
                    <div class="recommended">荐</div>
                    <el-image class="scale" :src="imgScale" fit="fill"></el-image>
                </div>
            </div>

            <div class="tip">
                <div v-show="showYear">
                    应付金额：<span>{{memberVip.yearPrice}}</span>元
                </div>
                <div v-show="showDay">
                    应付金额：<span>{{memberTemp.dayPrice}}</span>元
                </div>
            </div>

            <div class="pay-type">
                <div class="weixin">
                    <canvas id="weixin-code" class="weixin-code"></canvas>
                    <div class="type">
                        <el-image  :src="imgWx" fit="fill"></el-image>
                        <div>微信支付</div>
                    </div>

                </div>
                <div class="card">
                    <div class="bank">
                        <div class="bankAccount">
                            <span >{{bank.openBankAccount}}</span>
                        </div>
                        <div class="bankName">
                            <el-image  :src="imgBankName" fit="fill"></el-image>
                            <span >{{bank.openBankUserName}}</span>
                        </div>
                        <div  class="bankName">
                            <el-image  :src="imgBank" fit="fill"></el-image>
                            <span >{{bank.openBankName}}</span>
                        </div>
                    </div>
                    <div class="imag">
                        <el-image  :src="imgPhone" fit="fill"></el-image>
                        <span class="text">客服热线：</span>
                        <span class="tel">{{$variable.tel}}</span>
                    </div>
                </div>
            </div>
            <i class="el-icon-close" @click="closePay"></i>
        </div>


        <clear-drawer :show.sync="agreement.show" title="淘数网用户协议" hide-footer width="900px">
            <section class="agreement-popup">

            </section>
        </clear-drawer>

    </div>
</template>

<script>
import QRCode from 'qrcode'
let timerWechat = null;
let timeAli = null;
export default {
    props: {
        memberTemp: {},
        memberVip: {}
    },
    filter: {
        price(val) {
            let str = ''
            if(val >= 10000 ){
                str = (val/10000) + '万'
            }else{
                str = val.toFixed(2)
            }
            return str
        }
    },
    data() {
        return {
            imgScope: require('@/assets/img/member/scope.png'),
            imgOpen: require('@/assets/img/member/open.png'),
            imgTrue: require('@/assets/img/member/true.png'),
            imgFalse: require('@/assets/img/member/false.png'),
            imgRecom: require('@/assets/img/member/recom.png'),
            imgPay: require('@/assets/img/member/pay.png'),
            imgScale: require('@/assets/img/member/scale.png'),
            imgWx: require('@/assets/img/member/wx.png'),
            imgZfb: require('@/assets/img/member/zfb.png'),
            imgPhone: require('@/assets/img/member/phone.png'),
            imgBank: require('@/assets/img/member/bank.png'),
            imgBankName: require('@/assets/img/member/bankName.png'),
            tableData: [
                {
                    type: '上市药品',
                    desc: '国产药品、进口药品、原料药、化学药品目录集、中国新药批准信息',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '药品研发',
                    desc: '药物临床试验',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '药品研发',
                    desc: '药品注册与受理、一致性评价',
                    tourists: false,
                    open: true,
                    vip: true
                },
                {
                    type: '合理用药',
                    desc: '药品说明书、药品超说明书、医保目录',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '市场行情',
                    desc: '基药目录、中药保护品种、国家集采中选目录、省份集采数量信息',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '市场行情',
                    desc: '招标信息',
                    tourists: false,
                    open: true,
                    vip: true
                },
                {
                    type: '销售信息',
                    desc: '生物药品数据',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '销售信息',
                    desc: '总览图分析、按大类统计分析、按药品统计分析、按企业统计分析、按省份统计分析',
                    tourists: false,
                    open: true,
                    vip: true
                },
                {
                    type: '医学文献',
                    desc: '临床文献、临床路径、指南与共识',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '药品穿透',
                    desc: '按剂型找药、按成分找药、按疾病找药',
                    tourists: false,
                    open: true,
                    vip: true
                },
                {
                    type: '药品检查',
                    desc: '药品质量不合格公告、药品政策法规',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '药品标准',
                    desc: '仿制药参比制剂目录、参比制剂备案信息、中药材标准、中国药品标准、中药方剂',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '企业信息',
                    desc: '药品生产企业、GMP认证企业、药品经营企业、GSP认证企业、全国医院名录',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '竞争力分析',
                    desc: '企业新闻、产品新闻',
                    tourists: true,
                    open: true,
                    vip: true
                },
                {
                    type: '竞争力分析',
                    desc: '产品分析、竞品分析',
                    tourists: false,
                    open: true,
                    vip: true
                },
            ],
            showOpen: false,
            showYear: false,
            showDay: false,
            agreement: {
                agree: true,
                show: false,
            },
            //银行账号信息
            bank:{
                openBankName: '',
                openBankAccount: '',
                openBankUserName: '',
            },


        }
    },
    destroyed() {
        this.clearTimer()
    },
    created() {
        this.setrowspans(this.tableData)
    },
    methods: {
        clearTimer() {
            clearTimeout(timerWechat)
            clearTimeout(timeAli)
            setTimeout(() => {
                clearTimeout(timerWechat)
                clearTimeout(timeAli)
            }, 3000)
            setTimeout(() => {
                clearTimeout(timerWechat)
                clearTimeout(timeAli)
            }, 13000)
        },
        //获取银行信息
        getOpenBank() {
            this.$api.get('setting/bankInfo/bank').then(res=>{
                // 银行信息
                this.bank.openBankName = res.data.openBankName
                this.bank.openBankAccount = this.thousands(res.data.openBankAccount)
                this.bank.openBankUserName = res.data.openBankUserName
            })
        },
        thousands(num){
            let str = num.toString();
            let reg = str.indexOf(".") > -1 ? /(\d)(?=(\d{4})+\.)/g : /(\d)(?=(?:\d{4})+$)/g;
            return str.replace(reg,"$1 ");
        },
        // region 支付
        openYear() {
            this.showOpen = true
            this.clickYear()
            this.getOpenBank()
        },
        openDay() {
            this.showOpen = true
            this.clickDay()
            this.getOpenBank()
        },
        clickDay() {
            this.showDay = true
            this.showYear = false
            this.loadQrCode()
        },
        clickYear() {
            this.showYear = true
            this.showDay = false
            this.loadQrCode()
        },
        closePay() {
            this.showOpen = false
            this.clearTimer()
        },

        loadQrCode() {
            const param = {
                //number: 1,
            }
            if (this.showYear) {
                param.priceField = 'yearPrice'
                param.roleId = this.$variable.member.vip
            }  else {
                param.priceField = 'dayPrice'
                param.roleId = this.$variable.member.temp
            }

            // 微信二维码
            param.payChannel = 'wechat'
            this.$api.get('sys/sysUserPayment/insertSysUserPayment', param)
                .then(res => {
                    QRCode.toCanvas(document.getElementById("weixin-code"), res.data.qrCode, {width: 150,height:150})
                    this.clearTimer()
                    this.watchOrder(res.data.id)
                })

            // 支付宝二维码
            // param.payChannel = 'alipay'
            // this.$api.get('sys/sysUserPayment/insertSysUserPayment', param)
            //     .then(res => {
            //         QRCode.toCanvas(document.getElementById("zhifubao-code"), res.data, {width: 150,height:150})
            //         clearInterval(timeAli)
            //         timeAli = this.watchOrder(res.data.order_id)
            //     })
        },

        watchOrder(id) {
            if (id) {
                if (id) {
                    this.$api.post(`sys/sysUserPayment/querySysUserPayOrder?id=${id}&orderType=report`, {
                        orderId: id,
                        orderType: 'report',
                    })
                        .then(data => {
                            if (data.code === '00000') {
                                this.$store.commit('success', '支付成功')
                                setTimeout(() => {
                                    window.location.reload();
                                }, 1000)
                            } else {
                                timerWechat = setTimeout(() => {
                                    this.watchOrder(id)
                                }, 1000)
                            }
                        })
                        .catch(() => {
                            timerWechat = setTimeout(() => {
                                this.watchOrder(id)
                            }, 1000)
                        })
                }
            }
        },


        // endregion
        objectSpanMethod({ row, columnIndex }) {
            if (columnIndex === 0) {
                return {
                    rowspan: row.rowspan,
                    colspan: row.rowspan > 0 ? 1 : 0
                }
            }
        },
        setrowspans(list) {
            list.forEach(v => {
                v.rowspan = 1
            })
            // 双层循环
            for (let i = 0; i < list.length; i++) {
                for (let j = i + 1; j < list.length; j++) {
                    // 此处可根据相同字段进行合并，此处是根据的purchaseNo，实际情况根据业务需要修改
                    if (list[i].type === list[j].type) {
                        list[i].rowspan++
                        list[j].rowspan--
                    } else {
                        break
                    }
                }
                // 这里跳过已经合并的行数据，从下一次需要合并的行开始
                i = i + list[i].rowspan - 1
            }
        },
        discountHeaderStyle({ rowIndex }) {
            if(rowIndex === 1){
                return {display: 'none'}
            }
            return { height: '120px', color: '#fff', border: '0'}
        }
    }
}
</script>

<style lang="scss">
@import "~@/assets/css/var";
.mem-content{
    width: 1440px;
    margin: 0 auto 100px;
    .image{
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
    .table {
        .bold {
            font-weight: bold;
        }
        .el-table_1_column_1_column_3{
            .cell{
                display: unset;
            }
        }
        th.el-table__cell{
            overflow: unset;
        }
        .cell{
            display: flex;
            justify-content: center;
            align-content: center;
            overflow: unset;
            font-size: 13px;
            padding: 5px 0 !important;
            .open{
                width: 16px;
                height: 16px;
                display: flex;
                justify-content: center;
                align-content: center;
            }
        }
        .el-table--group, .el-table--border{
            border: 0;
        }
        .el-table--group::after, .el-table--border::after{
            width: 0;
        }
        .el-table {
            //overflow: unset;
            .el-table__header-wrapper{
                overflow: unset;
            }
            .has-gutter{
                font-size: 14px;
                tr:first-child{
                    th:first-child{
                        background-color: #585E6C ;
                    }
                    th:nth-of-type(2){
                        background-color: #3E4553 ;
                    }
                    th:nth-of-type(3){
                        background-color: #CD8844 ;
                    }
                    th:nth-of-type(4){
                        background-color: #A2682E ;
                    }
                }
                .trial{
                    position: relative;
                    .el-button{
                        background-color: transparent;
                        color: #fff;
                        border-color: #fff;
                    }
                    .price{
                        font-size: 18px;
                        font-weight: bold;
                        padding: 5px 0;
                    }
                    .recom{
                        position: absolute;
                        top: -18px;
                        left: 20px;
                    }
                    .recommended{
                        position: absolute;
                        top: -13px;
                        left: 32px;
                        font-size: 14px;
                        font-weight: bold;
                    }
                }
            }
            tbody{
                tr{
                    td {
                        padding: 6px 10px;
                    }
                    td:nth-last-child(3){
                        background-color: #F4F4F5;
                    }
                    td:nth-last-child(2){
                        background-color: #F7ECE1;
                    }
                    td:nth-last-child(1){
                        background-color: #F2E2C8;
                    }
                }
            }

        }
    }
    .open-pay{
        margin-top: 30px;
        height: 550px;
        background-color: #fff;
        padding: 0 178px;
        color:#3E4553;
        font-size: 14px;
        min-width: 800px;
        position: relative;
        .pay-content{
            padding: 20px 200px 20px;
            display: flex;
            justify-content: space-around;
            border-bottom: 1px solid #CECECE;
            .left{
                overflow: hidden;
            }
            .mem{
                &-title{
                    color: #333333;
                    font-size: 16px;
                }
                .money{
                    font-size: 40px;
                    color: #BF8A4B;
                    font-weight: bold;
                    span{
                        font-size: 16px;
                        color: #F93C3C ;
                        font-weight: unset;
                    }
                }
                height: 150px;
                width: 150px;
                padding: 28px;
                border: 1px solid #CECECE;
                display: flex;
                flex-direction: column;
                align-items: center;
                p{
                    padding: 10px 28px 28px;
                    text-align: center;
                    color: #999 ;
                    border-top: 1px solid #CECECE;
                }

            }
            .right{
                position: relative;
                .recom{
                    position: absolute;
                    top: -5px;
                    left: 15px;
                }
                .recommended{
                    position: absolute;
                    top: 1px;
                    left: 26px;
                    color: #fff;
                }
            }
            .active{
                position: relative;
                box-shadow: 0 0 3px 1px #D8A152;
                p{
                    border-top: 1px solid #D8A152;
                }
                .scale{
                    position: absolute;
                    bottom: -1px;
                    right: -1px;
                }
            }
        }
        .el-icon-close{
            position: absolute;
            color: $red;
            top: 10px;
            right: 10px;
            font-size: 30px;
        }
        .tip{
            div{
                text-align: center;
                padding: 20px;
                span{
                    color: #BF8A4B;
                }
            }
        }
        .pay-type{
            border-bottom: 1px solid #CECECE;
            padding-bottom: 10px;
            display: flex;
            align-items: center;
            .weixin{
                margin-left: 130px;
                display: flex;
                align-items: center;
                flex-direction: column;
                .type{
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    .el-image{
                        padding-right: 10px;
                    }
                }
                &-code{
                    height: 150px;
                    width: 150px;
                    border: 1px solid #09BB07;
                }
            }
            .zhifubao{
                margin-left: 80px;
                display: flex;
                align-items: center;
                flex-direction: column;
                .type{
                    display: flex;
                    align-items: center;
                    padding-top: 5px;
                    .el-image{
                        padding-right: 10px;
                    }
                }
                &-code{
                    height: 150px;
                    width: 150px;
                    border: 1px solid #5A9EF7;
                }
            }
            .card{
                margin-left: 40px;
                .bankText{
                    text-align: center;
                }
                .bank{
                    margin-left: 10px;
                    width: 260px;
                    height: 160px;
                    background-image: url('../../assets/img/member/bankCard.png');
                    background-size:contain;
                    background-repeat:no-repeat;
                    .text_margin{
                        margin-left: 10px;
                    }
                    .bankAccount{
                        padding-top: 78px;
                        text-align: center;
                        margin-bottom: 10px;
                        span{
                            color: #fff;
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                    .bankName{
                        font-size: 12px;
                        transform: scale(0.8);
                        transform-origin: 0px 0px;
                        color: #fff;
                        margin-left: 10px;
                        position: relative;
                        padding-left: 17px;
                        .el-image{
                            width: 5%;
                            position: absolute;
                            left: 0;
                        }
                    }
                }
                .imag{
                    display: flex;
                    align-items: center;
                    margin-left: 14px;
                    .el-image{
                        padding-right: 3px;
                        margin-top: 8px;
                    }
                    .text{
                        margin-top: 5px;
                    }
                }
                .tel{
                    color: #BF8A4B;
                    font-size: 26px;
                    font-weight: bold;
                }

            }
        }
        .agree{
            padding: 5px 0 0;
            color: #999;
        }
    }
}

</style>